import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify/slugify';
import moment from 'moment';
import { Field } from 'react-final-form';
import { KeyboardDatePicker, TextField } from 'mui-rff';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SLUGIFY_REGEX } from 'src/utils/consts';
import countryToFlag from 'src/utils/countryToFlag';
import { pageStatusValues } from 'src/utils/enums';
import WysiwygEditor from 'src/components/WysiwygEditor';

const useStyles = makeStyles((theme) => ({
  countryFiledIcon: {
    marginLeft: 6,
  },
  cardFooter: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  },
  tab: {
    minHeight: 44
  }
}));

const ArticleDetails = ({
  data,
  categoryList,
  setContent,
  setSlug
}) => {
  const classes = useStyles();
  const isNarrowTable = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  return (
    <Card>
      <CardHeader
        title={data ? `Edycja artykułu: ${data.title ?? ''} [ID: #${data.id}]` : 'Dodaj nowy artykuł'}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              inputProps={{
                onChange: (event) => {
                  setSlug(event.target.value);
                },
              }}
              label="Tytuł"
              name="title"
              required
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            {categoryList.length > 0 && (
              <TextField
                className={classes.typeSelect}
                fullWidth
                label="Kategoria"
                name="categoryId"
                required
                select
                variant="outlined"
              >
                <MenuItem
                  key=""
                  value=""
                />
                {categoryList.map((option) => (
                  <MenuItem
                    key={`category-${option.id}`}
                    value={option.id}
                  >
                    {countryToFlag(option.language)}
                    {' '}
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              label="Opis"
              name="description"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Field name="content">
              {({ meta }) => (
                <WysiwygEditor
                  value={data?.content ?? ''}
                  onChange={(value) => setContent(value)}
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <KeyboardDatePicker
              disableToolbar
              format="yyyy-MM-dd"
              label="Data"
              name="date"
              maxDate={new Date()}
              dateFunsUtils={DateFnsUtils}
              inputVariant="outlined"
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <Field
              format={(value) => {
                if (value) {
                  return slugify(value, { lower: true, remove: SLUGIFY_REGEX });
                }
                return '';
              }}
              name="slug"
            >
              {({ input, meta }) => (
                <MuiTextField
                  {...input}
                  fullWidth
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error ? meta.error[0] : undefined}
                  label="Slug"
                  name="slug"
                  required
                  variant="outlined"
                />
              )}
            </Field>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              label="Status"
              name="status"
              required
              select
              variant="outlined"
            >
              {pageStatusValues.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
              <MenuItem value="deleted">
                Usunięty
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {data && (
        <CardContent className={classes.cardFooter}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <Typography
                component="p"
                variant="caption"
              >
                Wyświetleń:
                {' '}
                {data.views}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              xs={12}
            >
              <Typography
                align={isNarrowTable ? 'left' : 'right'}
                component="p"
                variant="caption"

              >
                Ostatnia edycja:
                {' '}
                {moment(data.updated).format('DD MMMM YYYY HH:mm')}
                <br />
                Dodane:
                {' '}
                {moment(data.created).format('DD MMMM YYYY HH:mm')}
                {' '}
                {data.user && `przez ${data.user?.username}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

ArticleDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    categoryId: PropTypes.number,
    description: PropTypes.string,
    content: PropTypes.string,
    views: PropTypes.number,
    status: PropTypes.oneOf(pageStatusValues.map((option) => option.value)).isRequired,
    created: PropTypes.string,
    updated: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
  }),
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      language: PropTypes.string
    })
  ).isRequired,
  setContent: PropTypes.func.isRequired,
  setSlug: PropTypes.func.isRequired,
};

ArticleDetails.defaultProps = {
  data: null,
};

export default ArticleDetails;
