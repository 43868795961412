import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField as MuiTextField,
  makeStyles,
} from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import FilterIcon from '@material-ui/icons/FilterList';
import PostAddIcon from '@material-ui/icons/PostAdd';

import newsSelectors from 'src/store/selectors/news';

import countryToFlag from 'src/utils/countryToFlag';
import {
  codes as supportedLocalesCodes,
  labels as supportedLocalesLabels,
} from 'src/utils/locales';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filters: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.primary.dark,
    }
  },
  filtersActive: {
    background: theme.palette.primary.dark,
  },
  filtersActions: {
    justifyContent: 'center',
  },
  searchBar: {
    flex: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  searchBarContainer: {
    display: 'flex',
  },
}));

const Toolbar = ({ handleSearch }) => {
  const classes = useStyles();
  const [isFiltersOpen, setFiltersOpen] = useState(false);

  const filters = useSelector((state) => newsSelectors.getFilters(state));

  useEffect(() => {
    setFiltersOpen(Object.keys(filters).length > (filters.search ? 1 : 0));
  }, [filters]);

  const handleFilter = (values = {}) => {
    handleSearch(values);
  };

  const handleSearchInputChange = (value) => {
    const filtersArray = {
      ...filters,
    };

    if (value) {
      filtersArray.search = value;
    } else {
      delete filtersArray.search;
    }

    handleFilter(filtersArray);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        md={6}
        xs={12}
      >
        <Box className={classes.searchBarContainer}>
          <SearchBar
            className={classes.searchBar}
            onRequestSearch={handleSearchInputChange}
            onCancelSearch={handleSearchInputChange}
            placeholder="Szukaj aktualności"
            value={filters.search || ''}
          />
          <IconButton
            color="secondary"
            className={clsx(classes.filters, isFiltersOpen && classes.filtersActive)}
            onClick={() => setFiltersOpen(!isFiltersOpen)}
            variant="contained"
          >
            <FilterIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <Box display="flex" justifyContent="flex-end" style={{ gap: 8 }}>
          <Button
            color="secondary"
            component={RouterLink}
            startIcon={<PostAddIcon />}
            to="/news/add"
            variant="contained"
          >
            Dodaj aktualność
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Collapse in={isFiltersOpen}>
          <Form
            initialValues={filters}
            onSubmit={handleFilter}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <FormSpy subscription={{ errors: true, submitFailed: true, values: true }}>
                          {({ errors, submitFailed }) => (
                            <Autocomplete
                              autoHighlight
                              classes={{
                                option: classes.countryIcon,
                              }}
                              disableClearable
                              getOptionLabel={(option) => supportedLocalesLabels[option] || ''}
                              name="language"
                              options={supportedLocalesCodes}
                              renderOption={(option) => (option !== '' ? (
                                <>
                                  <span>{countryToFlag(option)}</span>
                                  {' '}
                                  {supportedLocalesLabels[option]}
                                </>
                              ) : '')}
                              renderInput={(params) => (
                                <MuiTextField
                                  {...params}
                                  className={classes.descriptionLanguageField}
                                  error={submitFailed && !!errors?.language}
                                  helperText={
                                    submitFailed && errors?.language && errors?.language[0]
                                  }
                                  label="Język"
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        </FormSpy>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Status"
                          name="status"
                          select
                          variant="outlined"
                        >
                          <MenuItem value="">
                            Wszystkie
                          </MenuItem>
                          <MenuItem value="hidden">
                            Ukryte
                          </MenuItem>
                          <MenuItem value="deleted">
                            Usunięte
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.filtersActions}>
                    <Button
                      color="secondary"
                      size="small"
                      type="submit"
                      variant="contained"
                    >
                      Filtruj
                    </Button>
                    <Button
                      onClick={() => {
                        if (filters.search) {
                          handleFilter({ search: filters.search });
                        } else {
                          handleFilter();
                        }
                      }}
                      size="small"
                      type="reset"
                    >
                      Wyczyść
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default Toolbar;
