import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  TextField,
  makeValidate,
} from 'mui-rff';
import * as Yup from 'yup';
import { Form } from 'react-final-form';

import {
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Fab,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  green,
  red,
} from '@material-ui/core/colors';
import CheckmarkIcon from '@material-ui/icons/Done';
import CrossIcon from '@material-ui/icons/Clear';

import {
  approveSuggestions,
  fetchSuggestionsDetails,
  rejectSuggestions,
} from 'src/store/actions/suggestions';

import { LATLNG_REGEXP, URL_REGEXP } from 'src/utils/consts';

import AlertDialog from 'src/components/AlertDialog';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';

import ChangesTable from './ChangesTable';
import HistoryTable from './HistoryTable';
import PlaceCard from './PlaceCard';
import UserCard from './UserCard';

import fieldsArray from './fieldsArray';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
  floatingActionButtonReject: {
    bottom: theme.spacing(12),
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  red: {
    '&:hover': {
      color: '#fff',
      backgroundColor: red[700],
    },
  },
}));

const schema = Yup.object().shape({
  title: Yup.string().nullable()
    .when('accept_title', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  country: Yup.string().nullable()
    .when('accept_country', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  region: Yup.string().nullable()
    .when('accept_region', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  type: Yup.string().nullable()
    .when('accept_type', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  latlng: Yup.string().nullable()
    .when('accept_latlng', {
      is: (val) => !!val,
      then: Yup.string()
        .matches(LATLNG_REGEXP, 'Niepoprawny format: xx.xxxx, xx.xxxx'),
    }),
  description: Yup.string().nullable()
    .when('accept_description', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  groundType: Yup.string().nullable()
    .when('accept_groundType', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  accessType: Yup.string().nullable()
    .when('accept_accessType', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  accessFor: Yup.string().nullable()
    .when('accept_accessFor', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  heightLimit: Yup.number().nullable()
    .when('accept_heightLimit', {
      is: (val) => !!val,
      then: Yup.number()
        .min(0.5, 'Wartość musi być większa od 0.5')
        .max(5, 'Wartość musi być mniejsza od 5'),
    }),
  gsmSignal: Yup.string().nullable()
    .when('accept_gsmSignal', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  camping_atmosphere: Yup.string().nullable()
    .when('accept_camping_atmosphere', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  camping_duration: Yup.string().nullable()
    .when('accept_camping_duration', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  camping_groundType: Yup.string().nullable()
    .when('accept_camping_groundType', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  camping_pets: Yup.string().nullable()
    .when('accept_camping_pets', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  camping_gsmSignal: Yup.string().nullable()
    .when('accept_camping_gsmSignal', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  tourist_attraction: Yup.string().nullable()
    .when('accept_tourist_attraction', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  service: Yup.string().nullable()
    .when('accept_service', {
      is: (val) => !!val,
      then: Yup.string()
        .required('Pole jest wymagane')
    }),
  email: Yup.string().nullable()
    .when('accept_email', {
      is: (val) => !!val,
      then: Yup.string()
        .email('Niepoprawny adres email'),
    }),
  website: Yup.string().nullable()
    .when('accept_website', {
      is: (val) => !!val,
      then: Yup.string()
        .matches(URL_REGEXP, 'Niepoprawny adres URL'),
    }),
  facebook: Yup.string().nullable()
    .when('accept_facebook', {
      is: (val) => !!val,
      then: Yup.string()
        .matches(URL_REGEXP, 'Niepoprawny adres URL'),
    }),
  streetViewUrl: Yup.string().nullable()
    .when('accept_streetViewUrl', {
      is: (val) => !!val,
      then: Yup.string()
        .matches(URL_REGEXP, 'Niepoprawny adres URL'),
    }),
});
const validate = makeValidate(schema);

const SuggestionsDetailView = ({ isArchived }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();

  const approveAlertRef = useRef();
  const deleteAlertRef = useRef();

  const [approveAlertConfirm, setApproveAlertConfirm] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [placeData, setPlaceData] = useState(null);
  const [suggestionsData, setSuggestionsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(fetchSuggestionsDetails(id));

      if (!isArchived && data.suggestions.status !== 'unverified') {
        navigate(`/suggestions/history/${data.suggestions.id}`, { replace: true });
      }

      setPlaceData(data.place);
      setSuggestionsData(data.suggestions);

      return data;
    };

    fetchData()
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message || 'Wystąpił błąd podczas pobierania danych', { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    if (!approveAlertConfirm) {
      approveAlertRef.current.open();
      return false;
    }

    const placeFormData = {};
    let historyData = {};

    fieldsArray.forEach((key) => {
      if (Object.keys(values).includes(key)) {
        if (values[`accept_${key}`]) {
          placeFormData[key] = values[key];
        }

        historyData = {
          ...historyData,
          [key]: {
            approved: values[`accept_${key}`],
            previous: placeData[key],
            new: values[key],
          }
        };
      }
    });

    if (values.latlng) {
      const [latitude, longitude] = values.latlng.split(',');

      if (values.accept_latlng) {
        placeFormData.latitude = latitude.trim();
        placeFormData.longitude = longitude.trim();
      }

      historyData = {
        ...historyData,
        latitude: {
          approved: values.accept_latlng,
          previous: placeData.latitude,
          new: latitude.trim(),
        },
        longitude: {
          approved: values.accept_latlng,
          previous: placeData.longitude,
          new: longitude.trim(),
        },
      };
    }

    const newImagesFields = Object.keys(values).filter((item) => item.match(/accept_file_/));

    if (newImagesFields.length > 0) {
      placeFormData.files = newImagesFields.map((key) => {
        const fileId = key.replace('accept_file_', '');
        const data = suggestionsData.files.find((file) => String(file.id) === fileId);

        data.approved = values[key];

        historyData = {
          ...historyData,
          images: [
            ...historyData.images || [],
            {
              filename: data.filename,
              approved: data.approved,
            }
          ],
        };

        return data;
      });
    }

    placeFormData.history = JSON.stringify(historyData);
    placeFormData.respondMessage = values.approveMessage;

    try {
      await dispatch(approveSuggestions(id, placeFormData));

      setApproveAlertConfirm(false);

      enqueueSnackbar('Zmiany zostały zapisane', {
        action: (
          <Button
            classes={{
              label: {
                color: '#fff',
              },
            }}
            size="small"
            onClick={() => {
              navigate(`/place/${placeData.id}`);
            }}
          >
            Otwórz miejsce
          </Button>
        ),
        variant: 'success',
      });

      setTimeout(() => {
        navigate('/suggestions');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const onDelete = async (values) => {
    try {
      setIsFetching(true);

      const placeFormData = {};
      let historyData = {};

      fieldsArray.forEach((key) => {
        if (Object.keys(values).includes(key)) {
          historyData = {
            ...historyData,
            [key]: {
              approved: false,
              previous: placeData[key],
              new: values[key],
            }
          };
        }
      });

      if (values.latlng) {
        const [latitude, longitude] = values.latlng.split(',');

        historyData = {
          ...historyData,
          latitude: {
            approved: false,
            previous: placeData.latitude,
            new: latitude,
          },
          longitude: {
            approved: false,
            previous: placeData.longitude,
            new: longitude,
          },
        };
      }

      const newImagesFields = Object.keys(values).filter((item) => item.match(/accept_file_/));

      if (newImagesFields.length > 0) {
        placeFormData.files = newImagesFields.map((key) => {
          const fileId = key.replace('accept_file_', '');
          const data = suggestionsData.files.find((file) => String(file.id) === fileId);

          data.approved = false;

          historyData = {
            ...historyData,
            images: [
              ...historyData.images || [],
              {
                filename: data.filename,
                approved: false,
              }
            ],
          };

          return data;
        });
      }

      placeFormData.history = JSON.stringify(historyData);
      placeFormData.respondMessage = values.rejectMessage;

      await dispatch(rejectSuggestions(id, placeFormData));
      enqueueSnackbar('Propozycje zmian zostały odrzucone', { variant: 'success' });

      setTimeout(() => {
        navigate('/suggestions');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const getInitialValues = () => {
    const values = {};

    if (suggestionsData && placeData) {
      if (
        suggestionsData.latitude !== placeData.latitude
        || suggestionsData.longitude !== placeData.longitude
      ) {
        values.latlng = `${suggestionsData.latitude}, ${suggestionsData.longitude}`;
        values.accept_latlng = true;
      }

      fieldsArray.forEach((field) => {
        if (suggestionsData[field] !== placeData[field]) {
          values[field] = suggestionsData[field];
          values[`accept_${field}`] = true;
        }
      });

      if (suggestionsData.files.length > 0) {
        suggestionsData.files.forEach((file) => {
          values[`accept_file_${file.id}`] = true;
        });
      }
    }

    return values;
  };

  return (
    <Page
      appBar={{
        title: isArchived ? 'Historia zmian' : 'Propozycje zmian',
        hasBackButton: true,
        backTo: '/suggestions',
      }}
      className={classes.root}
      title={`${isArchived ? 'Historia' : 'Propozycje'} zmian dla: ${placeData ? placeData.title : '...'}`}
    >
      {suggestionsData && placeData ? (
        <Form
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          validate={validate}
          render={({
            form,
            handleSubmit,
            submitting,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container maxWidth="lg">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <PlaceCard place={placeData} />
                      </Grid>
                      <Grid
                        item
                        md={8}
                        xs={12}
                      >
                        <UserCard
                          message={suggestionsData.notes}
                          user={suggestionsData.user}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    {isArchived ? (
                      <HistoryTable
                        history={JSON.parse(suggestionsData.history)}
                        place={placeData}
                        suggestions={suggestionsData}
                      />
                    ) : (
                      <ChangesTable
                        suggestions={suggestionsData}
                        place={placeData}
                      />
                    )}
                  </Grid>
                  {isArchived && suggestionsData.respondMessage && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Card>
                        <CardHeader title="Wiadomość do zgłaszającego" />
                        <Divider />
                        <CardContent>
                          <Typography>
                            {suggestionsData.respondMessage}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Container>
              {!isArchived && (
                <>
                  <Tooltip
                    placement="left"
                    title="Odrzuć"
                  >
                    <Fab
                      aria-label="reject"
                      className={clsx(
                        classes.floatingActionButton,
                        classes.floatingActionButtonReject,
                        classes.red,
                      )}
                      disabled={submitting}
                      onClick={() => {
                        deleteAlertRef.current.open();
                      }}
                    >
                      {!submitting
                        ? <CrossIcon />
                        : <CircularProgress color="secondary" size={26} />}
                    </Fab>
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title="Zaakceptuj"
                  >
                    <Fab
                      aria-label="approve"
                      className={clsx(
                        classes.floatingActionButton,
                        classes.green,
                      )}
                      disabled={submitting}
                      type="submit"
                    >
                      {!submitting
                        ? <CheckmarkIcon />
                        : <CircularProgress color="secondary" size={26} />}
                    </Fab>
                  </Tooltip>
                  <AlertDialog
                    title="Potwierdź wprowadzenie zmian"
                    message="Zaznaczone pole zostaną nadpisane proponowanymi wartościami, a zgłaszający użytkownik zostanie powiadomiony o tym fakcie mailowo oraz otrzyma punkty za aktywność."
                    ref={approveAlertRef}
                    actions={[
                      {
                        label: 'Wróć',
                      },
                      {
                        autoFocus: true,
                        label: 'Zapisz',
                        onClick: () => {
                          approveAlertRef.current.close();
                          setApproveAlertConfirm(true);

                          setTimeout(() => {
                            form.submit();
                          });
                        },
                        type: 'submit',
                        variant: 'contained',
                      },
                    ]}
                  >
                    <TextField
                      fullWidth
                      label="Wiadomość dla użytkownika"
                      multiline
                      name="approveMessage"
                      minRows={5}
                      variant="outlined"
                    />
                  </AlertDialog>
                  <AlertDialog
                    title="Potwierdź odrzucenie zmian"
                    message="Chcesz odrzucić wszystkie zmiany? Zgłaszający użytkownik zostanie powiadomiony o tym fakcie mailowo i nie otrzyma punktów za aktywność."
                    ref={deleteAlertRef}
                    actions={[
                      {
                        label: 'Wróć',
                      },
                      {
                        className: classes.deleteButton,
                        disabled: isFetching,
                        label: 'Odrzuć',
                        loading: isFetching,
                        onClick: () => {
                          onDelete(values);
                          deleteAlertRef.current.close();
                        },
                      },
                    ]}
                  >
                    <TextField
                      fullWidth
                      label="Wiadomość dla użytkownika"
                      multiline
                      name="rejectMessage"
                      minRows={5}
                      variant="outlined"
                    />
                  </AlertDialog>
                </>
              )}
            </form>
          )}
        />
      ) : <Loading />}
    </Page>
  );
};

SuggestionsDetailView.propTypes = {
  isArchived: PropTypes.bool,
};

SuggestionsDetailView.defaultProps = {
  isArchived: false,
};

export default SuggestionsDetailView;
